import ApiService from "@/core/services/ApiService";

const ADD_CATEGORY = "ideabox/add-category/";
const ADD_IDEA = "ideabox/add-idea/";
const UPDATE_IDEA = "ideabox/update-idea/<pk>/";
const GET_CATEGORIES = "ideabox/get-categories";
const GET_CATEGORY = "ideabox/get-category/category_pk";
const UPDATE_CATEGORY = "ideabox/update-category/<pk>/";
const DELETE_CATEGORY = "ideabox/delete-category/<pk>/";
const DELETE_IDEA = "ideabox/delete-idea/<pk>/";
const LIKE_IDEA = "ideabox/like-idea/<pk>";

export default {
    likeIdea(idea_id) {
        ApiService.setHeader();
        return ApiService.get(LIKE_IDEA.replace("<pk>", idea_id));
    },
    addCategory(action) {
        ApiService.setHeader();
        return ApiService.post(ADD_CATEGORY, action);
    },
    deleteCategory(category_id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_CATEGORY.replace("<pk>", category_id));
    },
    updateCategory(category_id, idea) {
        ApiService.setHeader();
        return ApiService.put(UPDATE_CATEGORY.replace("<pk>", category_id), idea);
    },
    addIdea(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_IDEA, payload);
    },
    updateIdea(idea_id, idea) {
        ApiService.setHeader();
        return ApiService.put(UPDATE_IDEA.replace("<pk>", idea_id), idea);
    },
    deleteIdea(idea_id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_IDEA.replace("<pk>", idea_id));
    },
    getCategory(category_pk) {
        ApiService.setHeader();
        return ApiService.get(GET_CATEGORY.replace("category_pk", category_pk)).then((response) => {
            return response.data;
        });
    },
    getCategories() {
        ApiService.setHeader();
        return ApiService.get(GET_CATEGORIES).then((response) => {
            return response.data;
        });
    },
};
